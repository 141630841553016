import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

// Create the context
const MaintenanceCountContext = createContext();

// Context provider component
export const MaintenanceCountProvider = ({ children }) => {
  const [maintenanceCount, setMaintenanceCount] = useState(0);

  // Function to fetch maintenance count from the backend
  const fetchMaintenanceCount = async () => {
    try {
      const response = await axios.get("https://api.crm.sugarprojects.com/wp-json/wp/v2/websites?_embed&acf_format=standard&per_page=100");
      const websites = response.data;
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      // Recalculate the count based on websites that need maintenance and last maintenance date
      const count = websites.reduce((acc, site) => {
        const needsMaintenance = site.acf.maintenance;
        const lastMaintenanceDate = parseDate(site.acf.last_maintenance);
        
        // Only increment the count if the lastMaintenanceDate is valid and needsMaintenance is true
        if (needsMaintenance && lastMaintenanceDate && lastMaintenanceDate < thirtyDaysAgo) {
          return acc + 1;
        }
        return acc;
      }, 0);

      //console.log(websites);

      // Update the global context with the new maintenance count
      setMaintenanceCount(count);
    } catch (error) {
      console.error("Failed to fetch maintenance count:", error);
    }
  };

  // Helper function to parse dates in multiple formats (dd/mm/yyyy, yyyy-mm-dd, yyyymmdd)
  const parseDate = (dateString) => {
    // If the dateString is null, undefined, or empty, return null
    if (!dateString) {
      return null;
    }

    let date;

    // Handle yyyymmdd format (e.g., 20240412)
    if (dateString.length === 8 && /^\d{8}$/.test(dateString)) {
      // Convert yyyymmdd to yyyy-mm-dd (e.g., 20240412 -> 2024-04-12)
      dateString = dateString.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }

    // If the date is in ISO format (yyyy-mm-dd)
    if (dateString.includes('-')) {
      date = new Date(dateString); // directly create Date from ISO string
    } else if (dateString.includes('/')) {
      // If the date is in dd/mm/yyyy format
      const [day, month, year] = dateString.split('/').map(part => parseInt(part, 10));
      date = new Date(Date.UTC(year, month - 1, day)); // month - 1 because months are 0-indexed in JavaScript
    }

    // Check if the date is valid
    if (isNaN(date)) {
      console.error("Invalid date:", dateString);
      return null; // Return null for invalid dates
    }

    return date;
  };

  return (
    <MaintenanceCountContext.Provider value={{ maintenanceCount, setMaintenanceCount, fetchMaintenanceCount }}>
      {children}
    </MaintenanceCountContext.Provider>
  );
};

// Custom hook to use the context
export const useMaintenanceCount = () => {
  return useContext(MaintenanceCountContext);
};
import React, { useState, useEffect } from 'react';
import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useData } from './DataContext'; // Import your context

const WebsiteDrawer = ({ isOpen, onClose, client, onSubmit }) => {
  const [websiteName, setWebsiteName] = useState('');
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(client || '');
  const { refreshData } = useData();

  // Fetch clients only if no client is provided via props
  useEffect(() => {
    if (!client && isOpen) {
      fetch('https://api.crm.sugarprojects.com/wp-json/wp/v2/clients')
        .then((response) => response.json())
        .then((data) => {
          setClients(data);
        })
        .catch((error) => {
          console.error('Error fetching clients:', error);
        });
    }
  }, [client, isOpen]);

  const handleAddWebsite = async (e) => {
    e.preventDefault();
  
    // Check if the website name is provided and a client is selected
    if (websiteName.trim() && (client || selectedClient)) {
      try {
        console.log(selectedClient);
        
        // Wait for the website addition to complete
        const success = await onSubmit(websiteName, selectedClient);
        
        // Only proceed if the website was added successfully
        if (success) {
          refreshData();
  
          setWebsiteName(''); // Clear the website name field
          setSelectedClient(''); // Clear selected client if needed
          onClose(); // Close the drawer
        }
      } catch (error) {
        console.error('Error adding website:', error);
        // Optionally handle the error (e.g., show an error message)
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {/* Background overlay */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-40" />

      {/* Drawer container */}
      <div className="fixed inset-0 overflow-hidden z-50">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel className="pointer-events-auto w-screen max-w-md transform transition-all">
              <form onSubmit={handleAddWebsite} className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="bg-pink-700 px-4 py-6 sm:px-6">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-base font-semibold text-white">Add New Website</DialogTitle>
                    <button
                      type="button"
                      onClick={onClose}
                      className="rounded-md bg-pink-700 text-pink-200 hover:text-white"
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <p className="mt-1 text-sm text-pink-300">
                    Enter the name of the new Website you want to add.
                  </p>
                </div>
                <div className="flex flex-1 flex-col justify-between">
                  <div className="px-4 py-5 sm:p-6">
                    <div>
                      <label htmlFor="website-name" className="block text-sm font-medium text-gray-900">
                        Website Name
                      </label>
                      <input
                        type="text"
                        id="website-name"
                        value={websiteName}
                        onChange={(e) => setWebsiteName(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                      />
                    </div>

                    {/* Client Selection */}
                    {!client && (
                      <div className="mt-4">
                        <label htmlFor="client-select" className="block text-sm font-medium text-gray-900">
                          Assign Client
                        </label>
                        <select
                          id="client-select"
                          value={selectedClient}
                          onChange={(e) => setSelectedClient(e.target.value)}
                          className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                          required
                        >
                          <option value="" disabled>Select a client</option>
                          {clients.map((client) => (
                            <option key={client.id} value={client.id}>
                              {client.title.rendered}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end p-4">
                    <button
                      type="button"
                      onClick={onClose}
                      className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-4 rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
                    >
                      Add Website
                    </button>
                  </div>
                </div>
              </form>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default WebsiteDrawer;

import React, { useEffect, useState } from 'react';
import { useData } from './DataContext'; // Import the context
import axios from 'axios';

const ContactsPage = () => {
  const [groupedContacts, setGroupedContacts] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [editingContact, setEditingContact] = useState(null); // Track contact being edited
  const [editFormData, setEditFormData] = useState({}); // Temporary form data for editing
  const { websites, setWebsites, setError } = useData();
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const uniqueContactsMap = new Map();
        websites.forEach((website) => {
          if (website.acf && Array.isArray(website.acf.contacts)) {
            const logoUrl = website.acf.logo;
            const websiteUrl = website.acf.frontend_link;

            website.acf.contacts.forEach((contact) => {
              if (contact && contact.email) {
                uniqueContactsMap.set(contact.email, {
                  ...contact,
                  logo: logoUrl,
                  websiteUrl: websiteUrl,
                });
              }
            });
          }
        });

        const uniqueContacts = Array.from(uniqueContactsMap.values());

        const grouped = uniqueContacts.reduce((acc, contact) => {
          const letter = contact.name.charAt(0).toUpperCase();
          if (!acc[letter]) {
            acc[letter] = [];
          }
          acc[letter].push(contact);
          return acc;
        }, {});

        Object.keys(grouped).forEach((letter) => {
          grouped[letter].sort((a, b) => a.name.localeCompare(b.name));
        });

        setGroupedContacts(grouped);
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    };

    fetchContacts();
  }, [websites]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleEditClick = (contact) => {
    setEditingContact(contact.email); // Set the contact being edited
    setEditFormData(contact); // Initialize the edit form with current contact data
  };

  const handleEditChange = (field, value) => {
    setEditFormData((prev) => ({ ...prev, [field]: value }));
  };

  const saveContact = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
  
      // Check if websites exist and have the expected structure
      if (!websites || websites.length === 0) {
        throw new Error("No websites data available");
      }
  
      // Find the website containing the editing contact
      const website = websites.find((web) =>
        web.acf?.contacts?.some((c) => c.email === editingContact)
      );
  
      if (!website) {
        throw new Error("Website containing the contact not found");
      }
  
      // Fetch the current website data to ensure up-to-date information
      const websiteResponse = await axios.get(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${website.id}`,
        { headers }
      );
  
      // Retrieve existing contacts and update the specific contact
      const existingContacts = websiteResponse.data.acf.contacts || [];
      const updatedContacts = existingContacts.map((contact) =>
        contact.email === editingContact ? { ...editFormData } : contact
      );
  
      // Update the website with the modified contacts list
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${website.id}`,
        {
          acf: {
            contacts: updatedContacts,
          },
        },
        { headers }
      );
  
      // Update the local websites state with the modified contact
      setWebsites((prevWebsites) =>
        prevWebsites.map((web) =>
          web.id === website.id ? { ...web, acf: { ...web.acf, contacts: updatedContacts } } : web
        )
      );
  
      // Reset state after successful save
      setEditingContact(null);
      setEditFormData({});
    } catch (error) {
      console.error("Error saving contact:", error.message);
      setError("Failed to save contact");
    }
  };
  
  

  const cancelEdit = () => {
    setEditingContact(null);
    setEditFormData({});
  };

  const filteredContacts = Object.keys(groupedContacts).reduce((acc, letter) => {
    const filtered = groupedContacts[letter].filter((contact) => {
      const { name, email, number, position, websiteUrl } = contact;
      return (
        name.toLowerCase().includes(searchQuery) ||
        email.toLowerCase().includes(searchQuery) ||
        (number && number.toLowerCase().includes(searchQuery)) ||
        (position && position.toLowerCase().includes(searchQuery)) ||
        websiteUrl.includes(searchQuery)
      );
    });
    if (filtered.length) acc[letter] = filtered;
    return acc;
  }, {});

  return (
    <div className="h-full w-full overflow-y-auto bg-white shadow">
      <h1 className="text-2xl font-semibold leading-6 text-gray-900 mt-6 ml-6">Contacts</h1>
      <div className="bg-white px-4 py-4 sm:px-6">
        <input
          type="text"
          placeholder="Search contacts..."
          className="w-full border-gray-300 rounded-md shadow-sm focus:border-pink-500 focus:ring-pink-500"
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>

      <nav aria-label="Directory">
        {Object.keys(filteredContacts).sort().map((letter) => (
          <div key={letter} className="relative">
            <div className="sticky top-0 z-10 bg-gray-50 px-3 py-1.5 text-sm font-semibold text-gray-900 border-t border-b border-gray-200">
              <h3>{letter}</h3>
            </div>
            <ul role="list" className="divide-y divide-gray-100">
              {filteredContacts[letter].map((contact) => (
                <li key={contact.email} className="relative flex flex-col px-4 py-5 hover:bg-gray-50 sm:px-6">
                  {editingContact === contact.email ? (
                    <div className="space-y-4">
                      <input
                        type="text"
                        value={editFormData.name || ""}
                        onChange={(e) => handleEditChange("name", e.target.value)}
                        className="w-full border-gray-300 rounded-md shadow-sm"
                        placeholder="Name"
                      />
                      <input
                        type="email"
                        value={editFormData.email || ""}
                        onChange={(e) => handleEditChange("email", e.target.value)}
                        className="w-full border-gray-300 rounded-md shadow-sm"
                        placeholder="Email"
                      />
                      <input
                        type="text"
                        value={editFormData.position || ""}
                        onChange={(e) => handleEditChange("position", e.target.value)}
                        className="w-full border-gray-300 rounded-md shadow-sm"
                        placeholder="Position"
                      />
                      <input
                        type="text"
                        value={editFormData.phone_1 || ""}
                        onChange={(e) => handleEditChange("phone_1", e.target.value)}
                        className="w-full border-gray-300 rounded-md shadow-sm"
                        placeholder="Phone 1"
                      />
                      <input
                        type="text"
                        value={editFormData.phone_2 || ""}
                        onChange={(e) => handleEditChange("phone_2", e.target.value)}
                        className="w-full border-gray-300 rounded-md shadow-sm"
                        placeholder="Phone 2"
                      />
                      <div className="flex justify-end gap-2">
                        <button onClick={cancelEdit} className="text-gray-600 hover:underline">
                          Cancel
                        </button>
                        <button
                          onClick={saveContact}
                          className="bg-pink-600 text-white px-4 py-2 rounded-md hover:bg-pink-500"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-between">
                      <div>
                        <p className="text-sm font-semibold text-gray-900">{contact.name}</p>
                        <p className="text-sm text-gray-500">{contact.email}</p>
                      </div>
                      <button
                        onClick={() => handleEditClick(contact)}
                        className="text-pink-600 hover:underline"
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </div>
  );
};

export default ContactsPage;

import React from 'react';
import { useData } from './DataContext';
import { Link } from 'react-router-dom';

const WebsiteJobsComponent = ({ website }) => {
  const { jobs } = useData();

  const relatedJobs = jobs.filter(job => {
    // Check if website is an array or a single value
    const jobWebsiteId = Array.isArray(job.acf?.website) 
      ? job.acf.website[0] 
      : job.acf?.website;
  
    // Check if website is an array or a single value
    const targetWebsiteId = Array.isArray(website.id) 
      ? website.id[0] 
      : website.id;
    
    return jobWebsiteId === targetWebsiteId;
  });


  return (
    <div className="mt-6 p-6 bg-white rounded-md shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold text-gray-900">Jobs:</h3>
      </div>
      {relatedJobs.length === 0 ? (
        <p className="text-gray-500">No jobs found for this website.</p>
      ) : (
        <div className="space-y-2">
          {relatedJobs.map(job => (
            <Link 
              key={job.id} 
              to={`/jobs/${job.id}`} 
              className="block p-3 bg-gray-50 rounded-md hover:bg-pink-50 transition-colors"
            >
              <div className="flex justify-between items-center">
                <span className="text-gray-800 font-medium">
                  {job?.title?.rendered}
                </span>
                <span className="text-sm text-gray-600">
                  Status: {job.acf?.status || 'N/A'}
                </span>
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default WebsiteJobsComponent;
import React, { useState } from "react";

const ChargeSelectionModal = ({ charges, onClose, onConfirm }) => {
  const [selectedCharges, setSelectedCharges] = useState(charges);

  const toggleCharge = (index) => {
    const updatedCharges = [...selectedCharges];
    updatedCharges[index].selected = !updatedCharges[index].selected;
    setSelectedCharges(updatedCharges);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-2xl shadow-lg w-96 max-w-full">
        <h2 className="text-xl font-semibold text-pink-600 mb-4">
          Select Charges to Include
        </h2>
        <ul className="space-y-3 max-h-60 overflow-y-auto">
          {selectedCharges.map((charge, index) => (
            <li key={charge.charge} className="flex items-center space-x-3">
              <input
                type="checkbox"
                checked={charge.selected}
                onChange={() => toggleCharge(index)}
                className="w-4 h-4 text-pink-600 border-gray-300 rounded focus:ring-0"
              />
              <label className="text-gray-700">{charge.charge}</label>
            </li>
          ))}
        </ul>
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 bg-gray-200 rounded-lg hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={() => onConfirm(selectedCharges)}
            className="px-4 py-2 text-white bg-pink-600 rounded-lg hover:bg-pink-500"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChargeSelectionModal;
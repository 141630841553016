import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await axios.post('https://api.crm.sugarprojects.com/wp-json/jwt-auth/v1/token', {
        username,
        password,
      });

      console.log('Login response:', response.data);
      const { token } = response.data;

      localStorage.setItem('token', token);
      localStorage.setItem('username', username);

      // Fetch user profile to get user_image
      const userProfileResponse = await axios.get('https://api.crm.sugarprojects.com/wp-json/wp/v2/users/me', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('User profile response:', userProfileResponse.data);

      const user_image = userProfileResponse.data.acf.user_image; // Assuming this is the correct key
      console.log('User image path:', user_image);

      if (user_image) {
        localStorage.setItem('user_image', user_image);
      } else {
        console.error('User image is not available in the profile response');
        localStorage.setItem('user_image', 'default_image_url.png'); // Set a default image if none found
      }

      setIsAuthenticated(true);
      navigate('/clients');
    } catch (err) {
      console.error('Error logging in', err);
      setError('Invalid username or password');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-pink-700 p-8 rounded-lg shadow-md w-full max-w-md">
        <div className="flex justify-center mb-6">
          <img
            src="https://sugarprojects.s3.eu-west-1.amazonaws.com/wp-content/uploads/2024/02/27161206/logo-one-colour.svg"
            alt="Company Logo"
            className="h-16" // Adjust height as needed
          />
        </div>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-white font-semibold mb-2">Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-2 border rounded-md bg-gray-100"
              placeholder="Enter your username"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-white font-semibold mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-md bg-gray-100"
              placeholder="Enter your password"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-white text-black py-2 rounded-md hover:bg-pink-100 transition duration-200"
          >
            Login
          </button>
        </form>
        {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
      </div>
    </div>
  );
};

export default Login;




import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { format, differenceInDays, parseISO } from 'date-fns';

const ReminderContext = createContext();

export const ReminderProvider = ({ children }) => {
  const [reminders, setReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchChargeReminders = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      const websitesResponse = await axios.get(
        'https://api.crm.sugarprojects.com/wp-json/wp/v2/websites?per_page=100',
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      const activeReminders = websitesResponse.data.flatMap(website => {
        return (website.acf.charges || [])
          .filter(charge => charge.reminder === true && charge.end_date)
          .map(charge => ({
            websiteId: website.id,
            websiteName: website.title.rendered,
            chargeName: charge.charge,
            endDate: charge.end_date,
            daysRemaining: differenceInDays(parseISO(charge.end_date), new Date())
          }))
          .filter(reminder => reminder.daysRemaining <= 30 && reminder.daysRemaining > 0)
      });

      setReminders(activeReminders);
    } catch (error) {
      console.error('Error fetching charge reminders:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Dismiss a reminder and save the dismissal in localStorage
  const dismissReminder = (websiteId, chargeName) => {
    const dismissalDate = new Date().toISOString();
    
    // Store the dismissal with the current date in localStorage
    const dismissedReminders = JSON.parse(localStorage.getItem('dismissedReminders')) || {};
    dismissedReminders[`${websiteId}-${chargeName}`] = dismissalDate;
    localStorage.setItem('dismissedReminders', JSON.stringify(dismissedReminders));
    
    // Remove from the state reminders list
    setReminders(currentReminders => 
      currentReminders.filter(reminder => 
        !(reminder.websiteId === websiteId && reminder.chargeName === chargeName)
      )
    );
  };

  // Clear dismissals that are older than 60 days
  const clearExpiredDismissals = () => {
    const dismissedReminders = JSON.parse(localStorage.getItem('dismissedReminders')) || {};
    const now = new Date();

    for (const [key, value] of Object.entries(dismissedReminders)) {
      const dismissalDate = new Date(value);
      const daysSinceDismissed = differenceInDays(now, dismissalDate);

      if (daysSinceDismissed > 60) {
        delete dismissedReminders[key]; // Remove the expired dismissal
      }
    }

    localStorage.setItem('dismissedReminders', JSON.stringify(dismissedReminders));
  };

  // Load dismissed reminders and filter out those expired
  useEffect(() => {
    clearExpiredDismissals(); // Clear expired dismissals on load

    fetchChargeReminders(); // Fetch and set current reminders
  }, []);

  const filteredReminders = reminders.filter(reminder => {
    const dismissedReminders = JSON.parse(localStorage.getItem('dismissedReminders')) || {};
    return !dismissedReminders[`${reminder.websiteId}-${reminder.chargeName}`];
  });

  return (
    <ReminderContext.Provider value={{ 
      reminders: filteredReminders, 
      isLoading, 
      fetchChargeReminders, 
      dismissReminder 
    }}>
      {children}
    </ReminderContext.Provider>
  );
};

export const ReminderNotifications = () => {
  const { reminders, dismissReminder } = useReminders();

  return (
    <div className="absolute right-0 top-full mt-2 w-80 bg-white shadow-lg rounded-md border">
      {reminders.length === 0 ? (
        <div className="p-4 text-gray-500 text-center">
          No upcoming charge reminders
        </div>
      ) : (
        <ul>
          {reminders.map((reminder) => (
            <li 
              key={`${reminder.websiteId}-${reminder.chargeName}`} 
              className="p-4 border-b hover:bg-gray-50 flex justify-between items-center"
            >
              <div>
                <div className="font-bold">{reminder.websiteName}</div>
                <div className="text-sm text-gray-600">
                  {reminder.chargeName} ends in {reminder.daysRemaining} days
                </div>
              </div>
              <button 
                onClick={() => dismissReminder(reminder.websiteId, reminder.chargeName)}
                className="text-pink-600 hover:text-pink-800"
              >
                Dismiss
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const useReminders = () => {
  const context = useContext(ReminderContext);
  if (!context) {
    throw new Error('useReminders must be used within a ReminderProvider');
  }
  return context;
};

export default ReminderProvider;

import React, { useEffect, useState } from "react";
import axios from "axios";
import WebsiteDrawer from "./WebsiteDrawer";
import { Link } from 'react-router-dom';
import WebsitesListComponent from './WebsiteListComponent';
import { useData } from './DataContext';

const WebsitesPage = () => {
  const { websites, setWebsites, addWebsite, setError } = useData();
  const [loading, setLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [serverFilter, setServerFilter] = useState("");
  const [filteredWebsites, setFilteredWebsites] = useState([]);

  // Synchronize filteredWebsites with websites when websites change
  useEffect(() => {
    setFilteredWebsites(websites);
  }, [websites]);

  // Extract unique server names
  const uniqueServers = Array.from(
    new Set(websites.map(website => website.acf?.nimbus_server || '').filter(Boolean))
  );

  // Helper function to filter websites based on search query and server filter
  useEffect(() => {
    let filtered = websites;

    // Apply server filter
    if (serverFilter) {
      filtered = filtered.filter(website => website.acf?.nimbus_server === serverFilter);
    }

    // Apply search query filter
    if (searchQuery) {
      const lowercasedQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(website => {
        const { title, acf } = website;
        return (
          title?.rendered?.toLowerCase().includes(lowercasedQuery) ||
          acf?.description?.toLowerCase().includes(lowercasedQuery)
        );
      });
    }

    setFilteredWebsites(filtered);
  }, [searchQuery, serverFilter, websites]);

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Websites</h1>
            <p className="mt-1 text-sm text-gray-500">List of current Sugarwebsites websites.</p>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0">
            <button
              type="button"
              onClick={() => setIsDrawerOpen(true)}
              className="relative inline-flex items-center rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
            >
              Add website
            </button>
          </div>
        </div>

        {/* Server Filter Radio Buttons */}
        <div className="mt-4 flex items-center space-x-4">
          <span className="text-sm font-medium text-gray-700">Filter by Server:</span>
          <div className="flex items-center space-x-2">
            <input
              type="radio"
              id="all-servers"
              name="server-filter"
              value=""
              checked={serverFilter === ""}
              onChange={() => setServerFilter("")}
              className="h-4 w-4 border-gray-300 text-pink-600 focus:ring-pink-500"
            />
            <label htmlFor="all-servers" className="text-sm text-gray-700">All Servers</label>
          </div>
          {uniqueServers.map(server => (
            <div key={server} className="flex items-center space-x-2">
              <input
                type="radio"
                id={`server-${server}`}
                name="server-filter"
                value={server}
                checked={serverFilter === server}
                onChange={() => setServerFilter(server)}
                className="h-4 w-4 border-gray-300 text-pink-600 focus:ring-pink-500"
              />
              <label htmlFor={`server-${server}`} className="text-sm text-gray-700">
                {server}
              </label>
            </div>
          ))}
        </div>
      </div>

      <WebsitesListComponent 
        websites={filteredWebsites} 
        delete={true} 
        showSearchBar={true} 
      />

      <WebsiteDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onSubmit={addWebsite}
      />
    </div>
  );
};

export default WebsitesPage;
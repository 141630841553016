// src/components/WordpressComponent.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const WordpressComponent = ({ externalSiteApiLink, website }) => {

    const getToken = async () => {
        try {
            console.log(website?.acf?.username, website?.acf?.password, externalSiteApiLink);
            const response = await axios.post(
                `${externalSiteApiLink}/wp-json/jwt-auth/v1/token`,
                {
                    username: website?.acf?.username,
                    password: website?.acf?.password,
                }
            );

            const { token } = response.data;
            return token;
        } catch (error) {
            console.error("Failed to get token:", error.response ? error.response.data : error.message);
            return null;
        }
    };

    const fetchPlugins = async () => {
        const token = await getToken();

        if (!token) {
            console.error("Token retrieval failed.");
            return [];
        }

        try {
            const response = await axios.get(`${externalSiteApiLink}/wp-json/wp/v2/plugins`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching plugins:', error);
            return [];
        }
    };

    const [plugins, setPlugins] = useState(website?.acf?.plugins || []);

    useEffect(() => {
        console.log(plugins);
    }, [plugins]);

    const handleUpdatePlugins = async () => {
        try {
            const externalPlugins = await fetchPlugins();
            const formattedPlugins = externalPlugins.map((plugin) => ({
                name: plugin.name || '', // Ensure the structure matches ACF fields
                version: plugin.version || '',
            }));
            console.log(formattedPlugins)
    
            const token = localStorage.getItem('token');
    
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${website.id}`,
                {
                    acf: {
                        plugins: formattedPlugins.map((plugin) => ({
                            name: plugin.name,
                            version: plugin.version,
                        })),
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            setPlugins(formattedPlugins); // Ensure UI shows the expected format
            alert('Plugins updated successfully!');
        } catch (error) {
            console.error('Error updating plugins:', error);
            alert('Failed to update plugins.');
        }
    };
    

    return (
        <div className="">
            <div className='flex justify-between align-middle mb-4'>
                <h1 className="text-xl font-semibold text-gray-800">Installed Plugins</h1>
                <button
                    className="rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
                    onClick={handleUpdatePlugins}
                >
                    Update
                </button>
            </div>
            <ul className="space-y-4">
                {plugins.length > 0 ? (
                    plugins.map((plugin, index) => (
                        <li
                            key={index}
                            className="p-4 border border-gray-200 rounded-lg shadow-sm flex justify-between items-center bg-gray-50"
                        >
                            <div className="text-md font-medium text-gray-700">{plugin.name}</div>
                            <div className="text-sm text-gray-500">Version {plugin.version}</div>
                        </li>
                    ))
                ) : (
                    <li className="text-gray-500">No plugins found.</li>
                )}
            </ul>
        </div>
    );
};

export default WordpressComponent;

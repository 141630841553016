import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import LogTable from "./LogTable";
import { useData } from './DataContext';
import axios from 'axios';

const WebsiteAutocomplete = ({ websites, selectedWebsite, setSelectedWebsite }) => {
    const [inputValue, setInputValue] = useState('');
    const [recommendations, setRecommendations] = useState([]);
  
    // Effect to set initial input value when selectedWebsite changes
    useEffect(() => {
      if (selectedWebsite) {
        const matchedWebsite = websites.find(website => website.id === selectedWebsite);
        if (matchedWebsite) {
          setInputValue(matchedWebsite.title.rendered);
        }
      } else {
        setInputValue('');
      }
    }, [selectedWebsite, websites]);
  
    const handleInputChange = (e) => {
      const value = e.target.value;
      setInputValue(value);
  
      // Filter websites that start with the input value
      const filteredWebsites = websites.filter(website => 
        website.title.rendered.toLowerCase().startsWith(value.toLowerCase())
      );
  
      setRecommendations(value ? filteredWebsites : []);
    };
  
    const handleRecommendationSelect = (website) => {
      setSelectedWebsite(website.id);
      setInputValue(website.title.rendered);
      setRecommendations([]);
    };
  
    return (
      <div className="w-full relative">
        <input
          type="text"
          id="website"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Select Website"
          className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
          required
        />
        {recommendations.length > 0 && (
          <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto">
            {recommendations.map((website) => (
              <li 
                key={website.id} 
                onClick={() => handleRecommendationSelect(website)}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              >
                {website.title.rendered}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

const JobPage = () => {
    const { websites, jobs, loading, error, refreshData } = useData();
    const { jobId } = useParams();

    const [job, setJob] = useState(null);
    const [logs, setLogs] = useState([]);
    const [localError, setLocalError] = useState(null);

    // Local state for form inputs
    const [title, setTitle] = useState("");
    const [onedriveLink, setOnedriveLink] = useState("");
    const [selectedWebsite, setSelectedWebsite] = useState("");
    const [poNumber, setPoNumber] = useState("");
    const [targetHours, setTargetHours] = useState("40");
    const [status, setStatus] = useState("");
    const [color, setColor] = useState("#ffffff"); // State for color picker

    useEffect(() => {
        if (loading) return;

        const foundJob = jobs.find(job => job.id === parseInt(jobId, 10));
        if (foundJob) {
            setJob(foundJob);
            setLogs(foundJob.acf?.logs || []);
            setTitle(foundJob.title?.rendered || "");
            setPoNumber(foundJob.acf?.po_number || "");
            setOnedriveLink(foundJob.acf?.onedrive_link || "");
            setSelectedWebsite(foundJob.acf?.website || "");
            setStatus(foundJob.acf?.status || "");
            setTargetHours(foundJob.acf?.target_hours || "40");
            console.log(foundJob.acf?.colour)
            setColor(foundJob.acf?.colour);
        } else {
            setLocalError("Job not found in context.");
        }
    }, [jobId, jobs, loading]); // Do not include `color` in dependencies

    useEffect(() => {
        console.log("color:", color)
    }, [color])

    const handleSaveChanges = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(
                `https://api.crm.sugarprojects.com/wp-json/wp/v2/jobs/${jobId}`,
                {
                    acf: {
                        logs,
                        target_hours: targetHours,
                        status,
                        website: selectedWebsite,
                        po_number: poNumber,
                        onedrive_link: onedriveLink,
                        colour: color, // Save the selected color
                    }
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            refreshData()
            alert("Changes saved successfully!");
        } catch (err) {
            console.error("Error saving job data:", err);
            alert("Failed to save changes.");
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div className="bg-white shadow sm:rounded-lg p-4">
            <div className="m-4 flex justify-between align-center">
                <div className='flex items-center'>
                    <h1 className="text-2xl font-semibold leading-6 text-gray-900">{title}</h1>
                    <input
                        type="color"
                        id="color-picker"
                        value={color}
                        onChange={(e) => setColor(e.target.value)}
                        className="ml-1 block w-6 h-6 border-0 rounded"
                    />
                </div>
                <button
                    onClick={handleSaveChanges}
                    className="rounded-md bg-pink-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
                >
                    Save Changes
                </button>
            </div>
            <div className="flex w-full justify-between">
                <div className="w-[19%]">
                    <label htmlFor="job-name" className="block text-sm font-medium text-gray-900">
                        Title
                    </label>
                    <input
                        type="text"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                    />
                </div>
                <div className="w-[19%]">
                    <label htmlFor="po_number" className="block text-sm font-medium text-gray-900">
                        Po Number
                    </label>
                    <input
                        type="text"
                        id="po_number"
                        value={poNumber}
                        onChange={(e) => setPoNumber(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                    />
                </div>
                <div className="w-[19%]">
                    <label htmlFor="onedrive" className="block text-sm font-medium text-gray-900">
                        Onedrive
                    </label>
                    <input
                        type="text"
                        id="onedrive"
                        value={onedriveLink}
                        onChange={(e) => setOnedriveLink(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                    />
                </div>
                <div className="w-[19%]">
                    <label htmlFor="website" className="block text-sm font-medium text-gray-900">
                        Website
                    </label>
                    <WebsiteAutocomplete 
                        websites={websites}
                        selectedWebsite={selectedWebsite}
                        setSelectedWebsite={setSelectedWebsite}
                    />
                </div>
                <div className="w-[19%]">
                    <label htmlFor="status" className="block text-sm font-medium text-gray-900">
                        Status
                    </label>
                    <select
                        id="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="mt-2 block w-full rounded-md border-0 shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
                        required
                    >
                        <option value="">Select Status</option>
                        <option value="upcoming">Upcoming</option>
                        <option value="ongoing">Ongoing</option>
                        <option value="completed">Completed</option>
                    </select>
                </div>
            </div>
            <LogTable logs={logs} setLogs={setLogs} targetHours={targetHours} setTargetHours={setTargetHours} />
        </div>
    );
};

export default JobPage;

// src/components/ChargesPage.js
import React, { useState, useEffect } from "react";
import { useData } from './DataContext';
import { Link } from 'react-router-dom';
import { Field, Label, Switch } from '@headlessui/react';

const ChargesPage = () => {
    const { websites } = useData();
    const [charges, setCharges] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [enabled, setEnabled] = useState(false);

    // Helper function to reformat dates
    const formatDate = (date) => {
        if (!date) return "N/A";
        const parsedDate = new Date(date);
        if (isNaN(parsedDate)) return "Invalid date";

        const day = String(parsedDate.getDate()).padStart(2, "0");
        const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
        const year = parsedDate.getFullYear();

        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        // Flatten charges across all websites and include website details
        const allCharges = websites.flatMap((website) => 
            (website.acf?.charges || []).map((charge) => ({
                ...charge,
                websiteTitle: website.title.rendered,
                websiteId: website.id,
            }))
        );

        // Sort charges by nearest end_date to farthest, placing invalid or past dates at the bottom
        allCharges.sort((a, b) => {
            const dateA = new Date(a.end_date);
            const dateB = new Date(b.end_date);
            const now = new Date();

            const isValidA = !isNaN(dateA) && dateA >= now;
            const isValidB = !isNaN(dateB) && dateB >= now;

            if (isValidA && isValidB) {
                return dateA - dateB;
            } else if (isValidA) {
                return -1;
            } else if (isValidB) {
                return 1;
            } else {
                return 1;
            }
        });

        setCharges(allCharges);
    }, [websites]);

    const filteredCharges = charges.filter(
        (charge) =>
            (charge?.charge?.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
            charge?.start_date?.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
            charge?.end_date?.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
            charge?.websiteTitle?.toLowerCase().startsWith(searchTerm.toLowerCase())) &&
            ((charge?.price > 0 &&
            charge?.end_date) ||
            enabled)
    );

    return (
        <div className="p-4">
            <div className="flex justify-between mb-4">
                <div className="ml-4 mt-4">
                    <h1 className="text-2xl font-semibold leading-6 text-gray-900">Charges</h1>
                </div>
                <div className="ml-4 mt-4 flex-shrink-0">
                    <Field className="flex items-center">
                        <Switch
                            checked={enabled}
                            onChange={setEnabled}
                            className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-pink-600"
                        >
                            <span
                                aria-hidden="true"
                                className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                            />
                        </Switch>
                        <Label as="span" className="ml-3 text-sm">
                            <span className="font-medium text-gray-900">Show All</span>{' '}
                        </Label>
                    </Field>
                </div>
            </div>

            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search charges..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full border-gray-300 rounded-md shadow-sm focus:border-pink-500 focus:ring-pink-500 mb-6"
            />

            {/* Charges Table */}
            {filteredCharges.length > 0 ? (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-200 rounded shadow-sm">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Charge</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Details</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Start Date</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">End Date</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Price</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Reminder</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">Website</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCharges.map((charge, index) => (
                                <tr key={index} className="hover:bg-gray-50">
                                    <td 
                                        className="px-4 py-2 text-sm text-gray-800 text-ellipsis overflow-hidden whitespace-nowrap" 
                                        title={charge.charge}
                                    >
                                        {charge.charge}
                                    </td>
                                    <td 
                                        className="px-4 py-2 text-sm text-gray-800 text-ellipsis overflow-hidden whitespace-nowrap" 
                                        title={charge.details}
                                    >
                                        {charge.details}
                                    </td>
                                    <td 
                                        className="px-4 py-2 text-sm text-gray-800 text-ellipsis overflow-hidden whitespace-nowrap" 
                                        title={formatDate(charge.start_date)}
                                    >
                                        {formatDate(charge.start_date)}
                                    </td>
                                    <td 
                                        className="px-4 py-2 text-sm text-gray-800 text-ellipsis overflow-hidden whitespace-nowrap" 
                                        title={formatDate(charge.end_date)}
                                    >
                                        {formatDate(charge.end_date)}
                                    </td>
                                    <td 
                                        className="px-4 py-2 text-sm text-gray-800 text-ellipsis overflow-hidden whitespace-nowrap" 
                                        title={`$${charge.price}`}
                                    >
                                        ${charge.price}
                                    </td>
                                    <td 
                                        className="px-4 py-2 text-sm text-gray-800 text-ellipsis overflow-hidden whitespace-nowrap" 
                                        title={charge.reminder ? "Yes" : "No"}
                                    >
                                        {charge.reminder ? "Yes" : "No"}
                                    </td>
                                    <td 
                                        className="px-4 py-2 text-sm text-pink-600 hover:text-pink-500 text-ellipsis overflow-hidden whitespace-nowrap"
                                        title={charge.websiteTitle}
                                    >
                                        <Link to={`/websites/${charge.websiteId}`}>
                                            {charge.websiteTitle}
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className="text-gray-500">No charges found.</p>
            )}
        </div>
    );
};

export default ChargesPage;

import React, { useState, useEffect } from 'react';
import { EnvelopeIcon, PhoneIcon, TrashIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import { useData } from './DataContext';

const ContactList = ({ websites, unique, cols, onContactDelete }) => {
  const { refreshData } = useData();

  const [contactDetails, setContactDetails] = useState([]);

  // Update contactDetails whenever websites changes
  useEffect(() => {
    if (!websites) return;

    let updatedContacts = websites.flatMap(website =>
      (website.acf.contacts || []).map((contact, contactIndex) => ({
        name: contact.name || "No name provided",
        email: contact.email,
        phone_1: contact.phone_1,
        phone_2: contact.phone_2,
        position: contact.position || "No position specified",
        logo: website.acf.logo,
        websiteTitle: website.title.rendered,
        websiteId: website.id,
        contactIndex: contactIndex, // Track the original index in the contacts array
      }))
    );

    if (unique) {
      updatedContacts = [
        ...new Map(
          updatedContacts.map(contact => [contact.email, contact])
        ).values(),
      ];
    }

    setContactDetails(updatedContacts);
  }, [websites, unique]);

  const handleDeleteContact = async (contactToDelete) => {
    try {
      // Retrieve the authentication token
      const token = localStorage.getItem('token');

      // Fetch the current website data to get the full contacts array
      const websiteResponse = await axios.get(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${contactToDelete.websiteId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Get the current contacts array
      const currentContacts = websiteResponse.data.acf.contacts || [];

      // Remove the specific contact
      const updatedContacts = currentContacts.filter((_, index) => 
        index !== contactToDelete.contactIndex
      );

      // Update the website with the new contacts array
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${contactToDelete.websiteId}`,
        {
          acf: {
            contacts: updatedContacts,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update local state to remove the contact
      setContactDetails(prevContacts => 
        prevContacts.filter(contact => 
          contact.email !== contactToDelete.email || 
          contact.websiteId !== contactToDelete.websiteId
        )
      );

      // Optional: Call parent component's callback if provided
      onContactDelete && onContactDelete(contactToDelete);

      refreshData();

    } catch (err) {
      console.error('Error deleting contact:', err);
      // Optionally, you could add error handling here, like showing a toast notification
    }
  };

  if (contactDetails.length === 0) {
    return <div>No contacts available</div>;
  }

  return (
    <div className="mt-6">
      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 mt-4">
        {contactDetails.map((contact, index) => (
          <li key={index} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow relative">
            {/* Delete Button */}
            <button onClick={() => handleDeleteContact(contact)} className="absolute top-2 right-2 text-red-500 hover:text-red-700">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-5 h-5 inline"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
              </svg>
            </button>

            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="truncate text-sm font-medium text-gray-900">{contact.name}</h3>
                </div>
                <p className="mt-1 truncate text-sm text-gray-700">{contact.position}</p>
                <p className="mt-1 truncate text-sm text-gray-500">{contact.phone_1}</p>
                <p className="mt-1 truncate text-sm text-gray-500">{contact.phone_2}</p>
              </div>
              <img 
                alt={`${contact.websiteTitle || "Website"} logo`} 
                src={contact.logo} 
                className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" 
              />
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200">
                <div className="flex w-0 flex-1">
                  <a
                    href={`mailto:${contact.email}`}
                    className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                    Email
                  </a>
                </div>
                <div className="-ml-px flex w-0 flex-1">
                  <a
                    href={`tel:${contact.phone_1}`}
                    className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                  >
                    <PhoneIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                    Call
                  </a>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContactList;
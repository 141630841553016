import React, { useState } from 'react';
import { useData } from './DataContext';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import ChargeSelectionModal from './ChargeSelectionModal';

const ExportAsCSVButton = ({ websiteData }) => {
  const [isExporting, setIsExporting] = useState(false);
  const [isExportingPDF, setIsExportingPDF] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ type: '', message: '' });
  const { decodeHtmlEntities } = useData();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCharges, setSelectedCharges] = useState([]);

  const openChargeSelection = () => {
    setIsModalOpen(true);
  };

  const handleChargeSelection = (charges) => {
    setSelectedCharges(charges.filter(c => c.selected));
    setIsModalOpen(false);
    exportAsPDF(charges.filter(c => c.selected));
  };

  const formatValue = (value) => {
    if (value === null || value === undefined) return '';
    const stringValue = String(value);
    return stringValue.includes(',') ? `"${stringValue.replace(/"/g, '""')}"` : stringValue;
  };

  const showNotification = (type, message) => {
    setToastMessage({ type, message });
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const isVersionOlder = (currentVersion, latestVersion) => {
    const current = currentVersion.split('.').map(Number);
    const latest = latestVersion.split('.').map(Number);

    for (let i = 0; i < Math.max(current.length, latest.length); i++) {
      const currentPart = current[i] || 0;
      const latestPart = latest[i] || 0;
      if (currentPart !== latestPart) {
        return currentPart < latestPart;
      }
    }
    return false;
  };

  const fetchPluginVersion = async (pluginSlug) => {
    try {
      const response = await fetch(`https://api.wordpress.org/plugins/info/1.0/${pluginSlug}.json`);
      if (!response.ok) return null;
      const data = await response.json();
      return data.version;
    } catch (error) {
      console.error(`Error fetching version for ${pluginSlug}:`, error);
      return null;
    }
  };

  const getPluginSlug = (pluginName) => {
    return pluginName
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  const exportAsCSV = async () => {
    try {
      setIsExporting(true);

      const flattenData = async (data) => {
        // Process charges, falling back to an empty array if charges are undefined
        const charges = (data.acf?.charges || []).map((charge) => ({
          "Charge Type": charge.charge,
          "Charge Details": charge.details,
          "Start Date": charge.start_date,
          "End Date": charge.end_date || "N/A",
          "Reminder": charge.reminder ? "Yes" : "No",
        }));

        // Process plugins, falling back to an empty array if plugins are undefined
        const pluginsPromises = (data.acf?.plugins || []).map(async (plugin) => {
          const pluginSlug = getPluginSlug(plugin.name);
          const latestVersion = await fetchPluginVersion(pluginSlug);

          let versionStatus = "Unknown";
          if (latestVersion) {
            if (plugin.version === latestVersion) {
              versionStatus = "Up to date";
            } else if (isVersionOlder(plugin.version, latestVersion)) {
              versionStatus = `Update available (${latestVersion})`;
            } else {
              versionStatus = "Version comparison error";
            }
          }

          return {
            "Plugin Name": decodeHtmlEntities(plugin.name),
            "Current Version": plugin.version,
            "Latest Version": latestVersion || "Unknown",
            "Status": versionStatus,
            "Needs Update": versionStatus.includes("Update available") ? "Yes" : "No"
          };
        });

        const plugins = await Promise.all(pluginsPromises);

        // Main website info
        const mainInfo = [{
          "Website ID": data.id,
          "Title": data.title.rendered,
          "Backend Platform": data.acf?.backend_platform || "N/A",
          "Frontend Platform": data.acf?.frontend_platform || "N/A",
          "Backend Link": data.acf?.backend_link || "N/A",
          "Frontend Link": data.acf?.frontend_link || "N/A",
          "Nimbus Server": data.acf?.nimbus_server || "N/A",
          "WordPress Version": data.acf?.wordpress_version || "N/A",
          "Yootheme Version": data.acf?.yootheme_version || "N/A",
          "Last Maintenance": data.acf?.last_maintenance || "N/A",
          "Maintenance": data.acf?.maintenance ? "Yes" : "No",
          "Plugins Needing Updates": plugins.filter(p => p["Needs Update"] === "Yes").length,
        }];

        return {
          mainInfo,
          charges,
          plugins,
        };
      };

      const { mainInfo, charges, plugins } = await flattenData(websiteData);

      const convertToCSV = (arr) => {
        if (!arr.length) return '';
        const header = Object.keys(arr[0]).join(',') + '\n';
        const rows = arr.map(row =>
          Object.values(row)
            .map(formatValue)
            .join(',')
        ).join('\n');
        return header + rows;
      };

      const sections = [
        '=== WEBSITE INFORMATION ===\n',
        convertToCSV(mainInfo),
        '\n=== CHARGES ===\n',
        convertToCSV(charges),
        '\n=== PLUGINS ===\n',
        convertToCSV(plugins)
      ];

      const csvBlob = new Blob(sections, { type: 'text/csv;charset=utf-8;' });
      const timestamp = new Date().toISOString().split('T')[0];
      const filename = `website_export_${timestamp}.csv`;

      const csvURL = URL.createObjectURL(csvBlob);
      const link = document.createElement('a');
      link.href = csvURL;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(csvURL);

      showNotification('success', `Data has been exported to ${filename}`);
    } catch (error) {
      console.error('Export failed:', error);
      showNotification('error', 'There was an error exporting the data. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  const exportAsPDF = (filteredCharges) => {
    try {
      setIsExportingPDF(true);
      const doc = new jsPDF();
      const charges = filteredCharges;
  
      // Add company logo
      const img = new Image();
      img.src = '/logo192.png';
      doc.addImage(img, 'PNG', 175, 10, 15, 15);
  
      // Header
      doc.setFontSize(12);
      doc.text(`SugarProjects Services:`, 20, 20);
  
      // Website URL
      doc.setFontSize(11);
      const frontendLink = websiteData.acf?.frontend_link || '';
      doc.text(frontendLink, 20, 35);
  
      // Services Table
      const tableData = charges.map(charge => [
        charge.charge,
        charge.details,
        charge.start_date || 'N/A',
        charge.end_date || 'N/A'
      ]);
  
      doc.autoTable({
        startY: 45,
        head: [['Service', 'Details', 'Start Date', 'End Date']],
        body: tableData,
        margin: { left: 20, right: 20 },
        styles: { fontSize: 10 },
        headStyles: { fillColor: [203, 213, 225] },
      });
  
      // Summary section with word wrapping
      const finalY = doc.previousAutoTable.finalY + 15;
      doc.setFontSize(11);
  
      // Footer
      doc.text('Please get in touch if you have any questions or new projects you\'d like to discuss.', 20, finalY);
      doc.text('Best regards,', 20, finalY + 10);
      doc.text('SugarProjects Team', 20, finalY + 20);
  
      // Generate filename with frontend link prefix
      const timestamp = new Date().toISOString().split('T')[0];
      const domainPrefix = frontendLink.split('.')[0]; // Extract only the part before the first dot
      const filename = `service_summary_${domainPrefix}_${timestamp}.pdf`;
  
      // Save PDF
      doc.save(filename);
      showNotification('success', `PDF has been exported as ${filename}`);
    } catch (error) {
      console.error('PDF export failed:', error);
      showNotification('error', 'There was an error exporting the PDF. Please try again.');
    } finally {
      setIsExportingPDF(false);
    }
  };
  

  return (
    <div className="relative">
      <button
        onClick={exportAsCSV}
        disabled={isExporting}
        className="flex items-center gap-2 px-4 py-2 font-medium text-white bg-pink-600 rounded hover:bg-pink-500 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <svg 
          className="w-4 h-4" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
        {isExporting ? 'Exporting...' : 'Export as CSV'}
      </button>

      <button
        onClick={openChargeSelection}
        disabled={isExportingPDF}
        className="flex items-center gap-2 px-4 py-2 mt-2 font-medium text-white bg-pink-600 rounded hover:bg-pink-500 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <svg 
          className="w-4 h-4" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
        {isExportingPDF ? 'Exporting...' : 'Export as PDF'}
      </button>
      {isModalOpen && (
        <ChargeSelectionModal 
          charges={websiteData.acf?.charges.map(charge => ({ ...charge, selected: true }))} 
          onClose={() => setIsModalOpen(false)} 
          onConfirm={handleChargeSelection} 
        />
      )}


      {showToast && (
        <div 
          className={`fixed bottom-4 right-4 p-4 rounded shadow-lg ${
            toastMessage.type === 'error' ? 'bg-red-500' : 'bg-green-500'
          } text-white`}
        >
          {toastMessage.message}
        </div>
      )}
    </div>
  );
};

export default ExportAsCSVButton;

import React, { useState } from "react";
import axios from "axios";

const WebsiteGeneralComponent = ({ website }) => {
  const [formData, setFormData] = useState({
    backend_platform: website.acf.backend_platform || "",
    backend_link: website.acf.backend_link || "",
    frontend_platform: website.acf.frontend_platform || "React", // Default value from WordPress or "React"
    frontend_link: website.acf.frontend_link || "",
    nimbus_server: website.acf.nimbus_server || "",
    nimbus_link: website.acf.nimbus_link || "",
    dev_start_date: website.acf.dev_start_date || "",
    live_date: website.acf.live_date || "",
    password: website.acf.password || "",
    username: website.acf.username || "",
  });

  const [isChanged, setIsChanged] = useState(false);

  // Static array of available platforms (hard-coded)
  const backendplatform = ["WordPress", "Strapi", "Joomla"];
  const frontendPlatforms = ["React", "Yootheme", "Nuxt", "Vue", "Elementor", "Zorka", "HTML"];
  const nimbusServer = ["Server 1", "Server 2"];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setIsChanged(true);
  };

  const handleSaveChanges = async () => {
    if (!website.id) {
      console.error("Website ID is undefined.");
      alert("Website ID is missing. Unable to save changes.");
      return;
    }

    try {
      const token = localStorage.getItem("token"); // Ensure you have the token
      await axios.post(
        `https://api.crm.sugarprojects.com/wp-json/wp/v2/websites/${website.id}`,
        { acf: formData },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsChanged(false);
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Failed to save changes.");
    }
  };

  return (
    <div className="relative p-6 pt-8 pb-12 bg-white rounded-md shadow-md">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl font-semibold">General Information</h3>
        <button
          onClick={handleSaveChanges}
          disabled={!isChanged} // Disable button if no changes
          className={`rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500 ${
            isChanged
              ? "bg-pink-700 hover:bg-pink-500"
              : "bg-gray-300 cursor-not-allowed"
          }`}
        >
          Save Changes
        </button>
      </div>

      <div className="space-y-4">
        {/* Two columns in a row */}
        <div className="flex space-x-4 mb-4">
          {/* Backend Platform */}
          <div className="w-1/2">
            <label className="block text-gray-700">Backend Platform</label>
            <select
              name="backend_platform"
              value={formData.backend_platform}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            >
              <option key={""} value={""}>
                  {"Select Backend"}
              </option>
              {backendplatform.map((backend) => (
                <option key={backend} value={backend}>
                  {backend}
                </option>
              ))}
            </select>
          </div>

          {/* Backend Link */}
          <div className="w-1/2">
            <label className="block text-gray-700">Backend Link</label>
            <input
              type="url"
              name="backend_link"
              value={formData.backend_link}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            />
          </div>
        </div>

        <div className="flex space-x-4 mb-4">
          {/* Frontend Platform */}
          <div className="w-1/2">
            <label className="block text-gray-700">Frontend Platform</label>
            <select
              name="frontend_platform"
              value={formData.frontend_platform}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            >
              <option key={""} value={""}>
                  {"Select Frontend"}
              </option>
              {frontendPlatforms.map((platform) => (
                <option key={platform} value={platform}>
                  {platform}
                </option>
              ))}
            </select>
          </div>

          {/* Frontend Link */}
          <div className="w-1/2">
            <label className="block text-gray-700">Frontend Link</label>
            <input
              type="url"
              name="frontend_link"
              value={formData.frontend_link}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            />
          </div>
        </div>

        <div className="flex space-x-4 mb-4">
          {/* Nimbus Server */}
          <div className="w-1/2">
            <label className="block text-gray-700">Nimbus Server</label>
            <select
              name="nimbus_server"
              value={formData.nimbus_server}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            >
              <option key={""} value={""}>
                  {"Select Server"}
              </option>
              {nimbusServer.map((server) => (
                <option key={server} value={server}>
                  {server}
                </option>
              ))}
            </select>
          </div>

          {/* Nimbus Link */}
          <div className="w-1/2">
            <label className="block text-gray-700">Nimbus Link</label>
            <input
              type="url"
              name="nimbus_link"
              value={formData.nimbus_link}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            />
          </div>
        </div>

        <div className="flex space-x-4 mb-4">
          {/* Start Date */}
          <div className="w-1/2">
            <label className="block text-gray-700">Dev Start Date</label>
            <input
              type="url"
              name="dev_start_date"
              value={formData.dev_start_date}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            />
          </div>

          {/* End Date */}
          <div className="w-1/2">
            <label className="block text-gray-700">Live Date</label>
            <input
              type="url"
              name="live_date"
              value={formData.live_date}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            />
          </div>
        </div>

        <div className="flex space-x-4 mb-4">
          {/* Username */}
          <div className="w-1/2">
            <label className="block text-gray-700">Admin Username</label>
            <input
              type="url"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            />
          </div>

          {/* Password */}
          <div className="w-1/2">
            <label className="block text-gray-700">Admin Password</label>
            <input
              type="url"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              className="w-full rounded-md border-0 shadow-sm ring-2 ring-gray-300 focus:ring-2 focus:ring-pink-600"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebsiteGeneralComponent;

// src/components/ClientsList.js
import React, { useState } from "react";
import ClientDrawer from './ClientDrawer';
import WebsiteDrawer from './WebsiteDrawer';
import { Link } from 'react-router-dom';
import { useData } from './DataContext';

const ClientsList = ({ onSelectClient }) => {
  const { clients, loading, addClient, deleteClient, refreshData } = useData();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isWebsiteDrawerOpen, setIsWebsiteDrawerOpen] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [visibleWebsites, setVisibleWebsites] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("")

  const handleAddClient = async (clientName) => {
    const success = await addClient(clientName);
    refreshData();
    if (success) setIsDrawerOpen(false);
  };

  const handleDeleteClient = async (clientId) => {
    await deleteClient(clientId);
  };

  const toggleWebsitesVisibility = (clientId) => {
    setVisibleWebsites((prev) => ({
      ...prev,
      [clientId]: !prev[clientId],
    }));
  };

  const handleClientSelect = (clientId) => {
    setSelectedClientId(clientId);
    onSelectClient(clientId); // Notify parent component if needed
  };

  // Filter posts based on the search term
  const filteredPosts = clients.filter((client) =>
    client.title.rendered.toLowerCase().startsWith(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h1 className="text-2xl font-semibold leading-6 text-gray-900">Clients</h1>
            <p className="mt-1 text-sm text-gray-500">List of current SugarProjects clients.</p>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0">
            <button
              type="button"
              onClick={() => setIsDrawerOpen(true)}
              className="inline-flex items-center rounded-md bg-pink-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-pink-500"
            >
              Add Client
            </button>
          </div>
        </div>
        {/* Search bar */}
        <div className="mt-4">
          <input
            type="text"
            placeholder="Search clients..."
            className="w-full px-3 py-2 border-0 rounded shadow-sm ring-1 ring-gray-300 focus:ring-pink-600"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>

      {loading && <p className="text-center py-4">Loading...</p>}
      {error && <p className="text-center text-red-500 py-4">{error}</p>}

      <div className="divide-y divide-gray-200 h-[70vh] overflow-y-scroll">
        {filteredPosts.map((post) => {
          const isSelected = post.id === selectedClientId;
          const isVisible = visibleWebsites[post.id];

          return (
            <div
              key={post.id}
              className={`py-4 px-4 sm:px-6 cursor-pointer ${isSelected ? "bg-slate-100" : "hover:bg-slate-50"}`}
              onClick={() => handleClientSelect(post.id)}
            >
              <div className="flex items-center justify-between">
                <h4 className="text-lg font-semibold text-gray-900 flex items-center">
                  <div className="text-pink-700">
                    {post.title.rendered}
                  </div>
                </h4>
              </div>
            </div>
          );
        })}
      </div>

      <ClientDrawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} onSubmit={handleAddClient} />
    </div>
  );
};

export default ClientsList;
import React, { useState, useMemo } from "react";

const MaintenanceHistory = ({ website }) => {
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [expandedRow, setExpandedRow] = useState(null);

  // Memoized filtered logs
  const filteredLogs = useMemo(() => {
    if (!selectedMonth && !selectedYear) return website.acf.maintenance_logs || [];

    return (website.acf.maintenance_logs || []).filter((log) => {
      const logDate = new Date(log.date);
      console.log(logDate);
      const monthMatches = selectedMonth ? logDate.getMonth() + 1 === parseInt(selectedMonth, 10) : true;
      const yearMatches = selectedYear ? logDate.getFullYear() === parseInt(selectedYear, 10) : true;
      return monthMatches && yearMatches;
    });
  }, [selectedMonth, selectedYear, website.acf.maintenance_logs]);

  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const handleYearChange = (e) => {
    const value = e.target.value;
    if (!value || /^\d{0,4}$/.test(value)) {
      setSelectedYear(value);
    }
  };

  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md mt-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Maintenance History</h2>
      <div className="flex flex-wrap gap-4 mb-6">
        {/* Month Selector */}
        <div className="flex flex-col">
          <select
            id="month-selector"
            className="border border-gray-300 rounded-md px-8 py-2 text-gray-700 focus:ring-pink-500 focus:border-pink-500"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
          >
            <option value="">- Month -</option>
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>
        </div>

        {/* Year Selector */}
        <div className="flex flex-col">
          <input
            id="year-input"
            type="number"
            placeholder="Year"
            className="border border-gray-300 rounded-md px-4 py-2 text-gray-700 focus:ring-pink-500 focus:border-pink-500 w-24"
            value={selectedYear}
            onChange={handleYearChange}
          />
        </div>
      </div>

      {/* Maintenance Logs Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead className="bg-gray-300 text-gray-700">
            <tr>
              <th className="px-4 py-2 text-left">Date</th>
              <th className="px-4 py-2 text-left">User</th>
              <th className="px-4 py-2 text-left">Start Time</th>
              <th className="px-4 py-2 text-left">End Time</th>
              <th className="px-4 py-2 text-left">Details</th>
            </tr>
          </thead>
          <tbody>
            {filteredLogs.map((log, index) => (
              <React.Fragment key={index}>
                <tr
                  onClick={() => toggleRowExpansion(index)}
                  className={`cursor-pointer border-t ${index % 2 === 0 ? "bg-gray-50" : "bg-white"} hover:bg-gray-100`}
                >
                  <td className="px-4 py-2">{log.date}</td>
                  <td className="px-4 py-2">{log.user}</td>
                  <td className="px-4 py-2">{log.start_time}</td>
                  <td className="px-4 py-2">{log.end_time}</td>
                  <td className="px-4 py-2 truncate">{log.details.slice(0, 20)}...</td>
                </tr>
                {expandedRow === index && (
                  <tr>
                    <td colSpan="5" className="px-4 py-2 bg-gray-100">
                      {log.details}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
            {filteredLogs.length === 0 && (
              <tr>
                <td colSpan="5" className="px-4 py-2 text-center text-gray-500">
                  No maintenance logs found for the selected period.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MaintenanceHistory;
